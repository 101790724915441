<script lang="ts">
  import CognitionLogo from "$lib/assets/logos/customers/CognitionLogo.svelte";
  import ContextualLogo from "$lib/assets/logos/customers/ContextualLogo.svelte";
  import CursorLogo from "$lib/assets/logos/customers/CursorLogo.svelte";
  import FableLogo from "$lib/assets/logos/customers/FableLogo.svelte";
  import RampLogo from "$lib/assets/logos/customers/RampLogo.svelte";
  import ScaleLogo from "$lib/assets/logos/customers/ScaleLogo.svelte";
  import SubstackLogo from "$lib/assets/logos/customers/SubstackLogo.svelte";
  import SunoLogo from "$lib/assets/logos/customers/SunoLogo.svelte";
</script>

<h3 class="text-base text-center mb-6 text-white/50">
  Customers run Modal to power data-intensive applications.
</h3>
<div class="grid grid-cols-2 md:grid-cols-4 gap-y-8 justify-items-center">
  <a target="_blank" rel="noreferrer" href="https://ramp.com">
    <RampLogo />
  </a>
  <a target="_blank" rel="noreferrer" href="https://scale.com">
    <ScaleLogo />
  </a>
  <a target="_blank" rel="noreferrer" href="https://suno.ai">
    <SunoLogo width={84} height={32} />
  </a>
  <a target="_blank" rel="noreferrer" href="https://substack.com">
    <SubstackLogo />
  </a>
  <a target="_blank" rel="noreferrer" href="https://cursor.sh/">
    <CursorLogo />
  </a>
  <a target="_blank" rel="noreferrer" href="https://www.fable.app/">
    <FableLogo width={96} height={36} />
  </a>
  <a target="_blank" rel="noreferrer" href="https://www.cognition-labs.com">
    <CognitionLogo />
  </a>
  <a target="_blank" rel="noreferrer" href="https://contextual.ai/">
    <ContextualLogo />
  </a>
</div>

<style lang="postcss">
  a {
    @apply w-28 h-9 flex justify-center items-center fill-white opacity-50 transition-all hover:fill-primary hover:opacity-100;
  }

  @screen sm {
    a {
      @apply w-full h-full;
    }
  }
</style>
